.logo {
    display: block;
    width: max-content;
    z-index: 999999;
    justify-self: left;
    grid-column: 1/2;
    grid-row: auto;
    padding-top: 8vw;
    /* padding: 1.5vw; */
    padding-left: 5vw;
  }

  .logo .logo-security {
    margin: auto;
    width: 10vw;
  }
  
  @media screen and (min-width: 1001px) {
    .logo {
      padding: 0vw;
      padding-left: 5vw;
      margin-top: 1.5vw;
    }
  
    .logo .logo-security {
      display: block;
      width: 3vw;
    }
  }
  