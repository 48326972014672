input[type="submit"],
select,
button {
  -webkit-appearance: none;
}

.login-container{
  justify-content: center;
  width: 100%;
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
}

.form-login-container{
    margin: 250px 0px 0px 0px;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 700px;
}

.message-login{
  /* text-align: center; */
  font-size: 30px;
  width: 100%;
  color: #ffffff;
}

.form-login {
  text-align: center;
  justify-self: center;
  background-color: #212529;
  border-radius: 6px;
  padding: 5%;
  margin: 25px;
  margin-top: 10%;
  margin-bottom: 10%;
  font-family: "Poppins", sans-serif;
  filter: drop-shadow(2px 3px 4px rgb(0, 0, 0, 0.5));
}

.contact-form {
  display: flex;
  flex-direction: column;
  font-weight: 70;
}

.contact-form input {
  padding-left: 10px;
  padding-top: 5px;
  height: 7vw;
  background-color: #ffffff;
  border-radius: 5px;
  max-height: 45px;
  font-size: 20px;
}

.contact-form ::placeholder {
  font-size: 20px;
  font-weight: 60;
}

.contact-form button[type="submit"] {
  background-color: #17824A;
  color:#ffffff;
  margin: 5% 0% 0% 22%;
  font-weight: 600;
  font-size: 1.5vw;
  border-radius: 5px;
  border: 0;
  width: 55%;
  height: 12vw;
  max-height: 65px;
}

@media screen and (min-width: 601px) {
  .form-login-container{
    margin: 250px 0px 0px 15px;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 700px;
  }

}

@media screen and (min-width: 751px) {
  .form-login-container{
    margin: 250px 0px 0px 75px;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 700px;
  }
}

@media screen and (min-width: 1001px) {

  .form-login-container{
    margin: 10% 0px 0px 6%;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 700px;
  }

    .contact-form input {
      padding-left: 10px;
      padding-top: 5px;
      max-height: 3.5vw;
      background-color: #ffffff;
      border-radius: 5px;
      font-size: 1.5rem;
    }
  
}