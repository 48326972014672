.modal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.60);
  display: none;
  justify-content: center;
  align-items: center;
}

/* css del contenido del popup de agradecimiento */

.modal-container {
  position: relative;
  min-width: 220px;
  min-height: 350px;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 2%;
  overflow-x: hidden;
}

.modal-container  .thanks-title{
  position: absolute;
  width: 85%;
  top: 150px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.modal-container .thanks-sub-title{
  position: absolute;
  width: 70%;
  height: 24px;
  top: 180px; 
  margin-top: 40px;
  font-size: 18px;
  font-weight: 400;
  text-align: center; 
}

.modal-close button {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.modal.is-open {
  display: flex;
}

@media screen and (min-width: 281px) {
  .modal-container {
    position: relative;
    min-width: 300px;
    min-height: 450px;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 2%;
    overflow-x: hidden;
  }
  
  .modal-container  .thanks-title{
    position: absolute;
    width: 90%;
    top: 200px;
    font-size: 27px;
    font-weight: 700;
    text-align: center;
  }
  
  .modal-container .thanks-sub-title{
    position: absolute;
    width: 75%;
    height: 24px;
    top: 200px; 
    margin-top: 40px;
    font-size: 26px;
    font-weight: 400;
    text-align: center; 
  }
}

@media screen and (min-width: 361px) {
  
  .modal-container  .thanks-title{
    font-size: 30px;
  }
  
  .modal-container .thanks-sub-title{
    font-size: 26px;
  }
}

@media screen and (min-width: 376px) {
  .modal-container {
    min-width: 350px;
    min-height: 475px;
  }
  
  .modal-container  .thanks-title{
    top: 200px;
    font-size: 32px;
  }
  
  .modal-container .thanks-sub-title{
    top: 210px; 
    font-size: 28px;
  }
}

@media screen and (min-width: 700px){
  .modal-container {
    position: relative;
    min-width: 650px;
    min-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .modal-container  .thanks-title{
    position: absolute;
    display: flex;
    width: 450px;
    top: 195px;
    font-size: 45px;
    font-weight: 700;
    left: 15%;
  }

  .modal-container .thanks-sub-title{
    position: absolute;
    width: 100%;
    height: 24px;
    top: 300px; 
    margin-top: 20px;
    font-size: 31px;
    font-weight: 400;
    text-align: center; 
    right: -8%;
  }
}

@media screen and (min-width: 1250px) {

  .modal-container {
    position: relative;
    min-width: 400px;
    min-height: 550px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 1%;
    padding-right: 1%;
  }

  .modal-container  .thanks-title{
    position: absolute;
    display: flex;
    width: 650px;
    top: 185px;
    font-size: 55px;
    font-weight: 700;
    left: 20%;
  }

  .modal-container .thanks-sub-title{
    position: absolute;
    width: 100%;
    height: 24px;
    top: 250px;
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    right: -8%;  
  }
}