.footer{
    justify-content: center;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    width: 100%;
    height: 100%;
}

.footer-icons-position{
    align-items: center;
    justify-content: center;
    width: 100%;
}

.footer-content-position h1{
    text-align: center;
    font-weight: 500;
    color: white;
    font-size: 1.15rem;
}

@media screen and (min-width: 281px) {
    
    
    .footer-content-position h1{
        font-size: 1.5rem;
    }    
}

@media screen and (min-width: 768px) {

    .footer-icons-position{
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .footer-content-position{
        align-items: center;
    }
    
    .footer-content-position h1{
        font-size: 1.5rem;
    }    
}

@media screen and (min-width: 1001px) {
    
    .footer-content-position h1{
        text-align: center;
        font-weight: 500;
        color: white;
        font-size: 1.5rem;
        margin-top: 0.75%;
    }
}