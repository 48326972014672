.home-sales{
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    width: 100%;
    height: 100%;
    margin-top: -7vw;
}

.home-security-content{
    align-items: center;
    text-align: center;
    position: absolute;
    justify-content: center;
    padding: 10% 0% 0% 0%;
    margin: 30% 7% 0% 7%;
    border-radius: 3%;
}


.home-security-title{
    text-align: left;
    font-weight: bolder;
    font-size: 1.75rem;
    width: 90%;
    margin: 0% 7% 0% 7%;
    color:#0F2835;
    font-weight: 750;
    line-height: 13vw;
}

.home-security-sub-title{
    text-align: left;
    font-weight: bolder;
    font-size: 1.25rem;
    width: 90%;
    margin: 7% 7% 5% 7%;
    font-weight: 300;
    color:#0F2835;
}

  .home-security-button {
    background-color: #17824A;
    color: #ffffff;
    margin: 7% 0% 0% 0%;
    margin-bottom: 15vw;
    font-weight: 600;
    width: 55%;
    border: 0;
    border-radius: 5px;
    height: 12vw;
    font-size: 6vw;
  }

@media screen and (min-width: 281px) {
    
    .home-security-title{
        font-size: 2.47rem;
    }
    .home-security-sub-title{
        font-size: 1.35rem;
    }
    
    .home-security-button {
        margin: 5% 0% 0% 0%;
        margin-bottom: 15vw;
    }
}


@media screen and (min-width: 1001px) {
    .home-sales{
        height: 345%;
        margin-top: -1vw;
    }

    .home-security-content{
        width: 35%;
        padding: 1% 0% 3% 0%;
        margin: 7% 5% 0% 33%;
        border-radius: 1%;
        background-color: rgb(0, 0, 0, 0.5);
        border-radius: 15px;
    }

    .home-security-title{
        text-align: left;
        font-weight: bolder;
        font-size: 3.75vw;
        width: 90%;
        margin: 3% 8% 2% 8%;
        color:#ffffff;
        border-color: #0F2835;
        font-weight: 600;
        line-height: 4.5vw;
    }
    .home-security-sub-title{
        text-align: center;
        font-weight: bolder;
        font-size: 1.5vw;
        width: 80%;
        margin: 0% 8% 0% 8%;
        font-weight: 350;
        color:#ffffff;
    }
    .home-security-button {
        background-color: #17824A;
        color: #ffffff;
        margin: 5% 40% 0% 0%;
        margin-bottom: 2vw;
        font-weight: 600;
        width: 45%;
        border: 0;
        border-radius: 5px;
        height: 3.5vw;
        font-size: 1.5vw;
        filter: drop-shadow(0px 4px 3px rgb(0, 0, 0, 0.5));
      }
}