input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
}

.fullPage {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 26vw;
  font-family: "Roboto", "Poppins", sans-serif;
  background-color: #ffffff;
}

.fullPage .top-bar {
  display: grid;
  background-color: #0F2835;
  grid-template-rows: 1fr;
  transition: 0.4s;
  position: fixed;
  width: 100%;
  z-index: 99;
  overflow: hidden;
  filter: drop-shadow(0px 4px 4px rgb(0, 0, 0, 25%));
  padding-bottom: 6vw;
}

.fullPage .home-security-position{
  grid-column: 1/1;
  grid-row: 2/2;
}

.footer-position {
  grid-column: 1;
  grid-row: 3/3;
  background: radial-gradient(48.05% 238.09% at 50% 50%, #1C556A 0%, #112F3D 100%);
  margin-top: 0vw;
  height: 39vw;
}

@media screen and (min-width: 436px) {
  .fullPage .contact-us-position {
    margin-top: 130vw;
  }
}

@media screen and (min-width: 768px) {
  .footer-position {
    height: 20vw;
  }

  .fullPage .contact-us-position {
    margin-top: 190vw;
  }
}

@media screen and (min-width: 1001px) {
  .fullPage {
    grid-template-columns: 40% 60%;
    grid-template-rows: 8vw 8vw;
    background-position: 0vw 40vw;
    background-size: 100vw 26vw;
    background-color: #ffffff;
  }

  .fullPage .top-bar {
    transition: 0.4s;
    position: fixed;
    width: 100%;
    z-index: 99;
    overflow: hidden;
    grid-column: 1/3;
    display: grid;
    grid-template-rows: 1fr;
    grid-row: 1/1;
    padding-bottom: 1vw;
  }
  
  .fullPage .home-security-position{
    grid-column: 1/3;
    grid-row: 2/2;
    margin-top: -4vw;
    /* justify-self: center; */
  }

  .footer-position {
    grid-column: 1/3;
    grid-row: 3/3;
    margin-top: 37vw;
    height: 3vw;
    z-index: 0;
  }
}
