.contact-us{
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    width: 100%;
    height: 100%;
    margin-top: 25vw;
}

.contact-us h2{
    padding: 0% 0% 10% 0%;
    margin: 0% 8% 0% 8%;
    font-size: 1.5rem;
    color: black;
    line-height: 1.5;
    font-weight: 700;
}

.contact-us .form-contact{
    margin: 0% 8% 0% 8%;
}

.contact-us input{
    border-radius: 2%;
    margin: 0% 0% 8% 0%;
    height: 15vw;
    padding-left: 5%;
    color: black;
    border-color: #9F9F9F;
    border-width: 1px;
}

.contact-us .input-name{
    width: 100%;
}

.contact-us .input-email{
    width: 100%;
}

.contact-us .input-phone-content{
    display: flex;
}

.contact-us .input-phone-content .input-phone{
    width: 70%;
    margin: 0% 0% 0% 0%;

}

.contact-us .input-phone-content .input-zip{
    margin: 0% 0% 0% 3%;
    width: 30%;
}

.contact-us button {
    background-color: #17824A;
    color: #ffffff;
    margin: 10% 0% 0% 15%;
    margin-bottom: 20vw;
    font-weight: 600;
    width: 70%;
    border: 0;
    border-radius: 5px;
    height: 16vw;
    font-size: 6.5vw;
    /* filter: drop-shadow(0px 4px 3px rgb(0, 0, 0, 0.5)); */
  }

  .contact-us button span{
    margin: 0% 3% -1% 0%;
  }

  .contact-us .checkGreen {
    display: block;
    text-align: center;
    align-items: center;
    margin-top: 0px;
  }
  
  .contact-us .checkGreen-container{
    min-width: 100px;
    min-height: 100px;
  }

  @media screen and (min-width: 281px) {
    .contact-us{
        margin-top: -40vw;
    }

    .contact-us h2{
        padding: 15% 0% 10% 0%;
        margin: 0% 8% 0% 8%;
        font-size: 1.95rem;
        line-height: 1.5;
    }

    .contact-us .checkGreen-container{
        min-width: 150px;
        min-height: 150px;
      }

  }

  @media screen and (min-width: 361px) {
    .contact-us{
        margin-top: -40vw;
    }

    .contact-us h2{
        padding: 15% 0% 10% 0%;
        margin: 0% 8% 0% 8%;
        font-size: 1.95rem;
        line-height: 1.5;
    }
  }

  @media screen and (min-width: 376px) {
    .contact-us{
        margin-top: -20vw;
    }

    .contact-us h2{
        padding: 15% 0% 11% 0%;
        margin: 0% 8% 0% 8%;
        font-size: 1.95rem;
        line-height: 1.5;
    }
  }

  @media screen and (min-width: 415px) {
    .contact-us{
        margin-top: -40vw;
    }
  }

  @media screen and (min-width: 500px) {
    .contact-us{
        margin-top: 20%;
    }
  }

  @media screen and (min-width: 768px) {
    .contact-us{
        margin-top: -68vw;
    }

    .contact-us h2{
        padding: 0% 0% 10% 0%;
        margin: 0% 8% 0% 8%;
        font-size: 1.75vw;
        line-height: 1.5;
    }

    .contact-us input{
        border-radius: 2%;
        margin: 0% 0% 5% 0%;
        height: 12vw;
        padding-left: 5%;
        color: black;
        border-color: #9F9F9F;
        border-width: 1px;
        font-size: 1.5rem;
    }

    .contact-us button {
        margin: 8% 0% 0% 23%;
        margin-bottom: 13vw;
        font-weight: 600;
        width: 55%;
        border: 0;
        border-radius: 5px;
        height: 12vw;
        font-size: 5.5vw;
      }
  }

  @media screen and (min-width: 1001px) {
    .contact-us{
        margin-top: -2vw;
    }

    .contact-us h2{
        text-align: center;
        padding: 7% 0% 4% 0%;
        margin: 0% 8% 0% 8%;
        font-size: 2.075vw;
        line-height: 1.5;
        color:#ffffff
    }
    
    .contact-us .form-contact{
        margin: 0% 7% 0% 7%;
    }
    
    .contact-us input{
        border-radius: 2%;
        margin: 0% 0% 0% 0%;
        height: 3.5vw;
        padding-left: 7%;
        color: black;
        border-color: #9F9F9F;
        border-width: 1px;
        font-size: 1.25vw;
    }
    
    .contact-us button {
        margin: 8% 0% 0% 0%;
        margin-bottom: 0vw;
        font-weight: 600;
        width: 50%;
        border: 0;
        border-radius: 5px;
        height: 3.5vw;
        font-size: 1.75vw;
      }
  }